<template>
  <div>
    <AppBar :title="$t('customers.headers.statistics')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-text v-if="statistics">
        <v-row>
          <v-col md="4">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">All users</td>
                    <td class="text-right">{{ statistics.inactiveUsers + statistics.activeUsers }}</td>
                  </tr>
                  <tr>
                    <td>Active</td>
                    <td class="text-right">{{ statistics.activeUsers }}</td>
                  </tr>
                  <tr>
                    <td>Inactive</td>
                    <td class="text-right">{{ statistics.inactiveUsers }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col cols="12" lg="3">
            <div id='chart'>
              <VueApexCharts type='pie' :options='nationalityOptions' :series='nationalitySeries'></VueApexCharts>
            </div>
            <v-data-table :items="statistics.nationalities" :headers="headers" :items-per-page="10" :page.sync="page1" @page-count="pageCount1 = $event" dense hide-default-header hide-default-footer>
              <template v-slot:item.name="{ item }">
                {{ getName(item.label) }}
              </template>
            </v-data-table>
            <div v-if="pageCount1 > 1" class="text-center pt-2">
              <v-pagination v-model="page1" :length="pageCount1" :total-visible="5" />
            </div>
          </v-col>
          <v-col cols="12" lg="3" offset-lg="1">
            <div id='chart'>
              <VueApexCharts type='pie' :options='countryOptions' :series='countrySeries'></VueApexCharts>
            </div>
            <v-data-table :items="statistics.countriesOfResidence" :headers="headers" :items-per-page="10" :page.sync="page2" @page-count="pageCount2 = $event" dense hide-default-header hide-default-footer>
              <template v-slot:item.name="{ item }">
                {{ getName(item.label) }}
              </template>
            </v-data-table>
            <div v-if="pageCount2 > 1" class="text-center pt-2">
              <v-pagination v-model="page2" :length="pageCount2" :total-visible="5" />
            </div>
          </v-col>
          <v-col cols="12" lg="3" offset-lg="1">
            <div id='chart'>
              <VueApexCharts type='pie' :options='ageOptions' :series='ageSeries'></VueApexCharts>
            </div>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, i) in statistics.ageRanges" :key="i">
                    <td>{{ item.label }}</td>
                    <td class="text-right">{{ item.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
import VueApexCharts from 'vue-apexcharts'
import countryCodes from '@/utils/countryCodes.json'
export default {
  name: 'Statistics',
  components: {
    VueApexCharts
  },
  data () {
    return {
      statistics: null,
      page1: 1,
      pageCount1: 0,
      page2: 1,
      pageCount2: 0,
      headers: [
        { text: '', value: 'name' },
        { text: '', value: 'label' },
        { text: '', value: 'value', align: 'right' }
      ],
      nationalitySeries: [],
      nationalityOptions: {
        chart: {
          type: 'pie'
        },
        labels: [],
        theme: {
          monochrome: {
            enabled: true
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        title: {
          text: 'Nationality'
        },
        dataLabels: {
          formatter (val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1) + '%']
          }
        },
        legend: {
          show: false
        }
      },
      countrySeries: [],
      countryOptions: {
        chart: {
          type: 'pie'
        },
        labels: [],
        theme: {
          monochrome: {
            enabled: true
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        title: {
          text: 'Country of Residence'
        },
        dataLabels: {
          formatter (val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1) + '%']
          }
        },
        legend: {
          show: false
        }
      },
      ageSeries: [],
      ageOptions: {
        chart: {
          width: '150px',
          type: 'pie'
        },
        labels: [],
        theme: {
          monochrome: {
            enabled: true
          }
        },
        // plotOptions: {
        //   pie: {
        //     dataLabels: {
        //       offset: -5
        //     }
        //   }
        // },
        title: {
          text: 'Age'
        },
        dataLabels: {
          formatter (val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1) + '%']
          }
        },
        legend: {
          show: false
        }
      }
    }
  },
  created () {
    this.getStatistics()
  },
  methods: {
    async getStatistics () {
      this.loading = true
      try {
        const { data } = await Service.get('DubaiCustomers/Statistics')
        this.statistics = data

        data.ageRanges.forEach(item => {
          this.ageSeries.push(item.value)
          this.ageOptions.labels.push(item.label)
        })

        data.nationalities.forEach(item => {
          this.nationalitySeries.push(item.value)
          // countryCodes.find(c => item.label === c.code).name
          this.nationalityOptions.labels.push(item.label)
        })

        data.countriesOfResidence.forEach(item => {
          this.countrySeries.push(item.value)
          this.countryOptions.labels.push(item.label)
        })

        // this.nationalitySeries =
        // this.nationalityOptions.labels =
      } catch (error) {}
      this.loading = false
    },
    getName (label) {
      return countryCodes.find(c => label === c.code).name
    }
  }
}
</script>
